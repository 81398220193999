import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import './GalleryImageStyles.scss'


const kimbleBuildGallery = graphql`
query BuildGalleryQuery {
  allFile(
    filter: {relativeDirectory: {eq: "gallery/KimbleBuild"}}
    sort: {fields: modifiedTime, order: ASC}
  ) {
    totalCount
    nodes {
      relativePath
      id
      modifiedTime
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          width: 600
          quality: 80
          tracedSVGOptions: {background: "#2C3D44"}
        )
      }
    }
  }
}
`

function KimbleBuildGalleryImage() {


  const data = useStaticQuery(kimbleBuildGallery)
  
    const imgNodes = data.allFile.nodes
  

  return (
    
      <div className="gallery-container" role="region" aria-label="Photo Gallery of Overland Rig Build">
        <p className="sr-only" tabIndex="0">Sorry, the photos in this gallery do not have alternative text.</p>
            {imgNodes.map((image, index)=>{
                const img = getImage(image)
                // console.log(image.node.childImageSharp)
                return <div key={"gal-"+index} className="gallery-img" aria-label={`Image ${index+1} of ${imgNodes.length}`} >
                          <div aria-label="Gallery Image">
                            <GatsbyImage image={img} alt="" />
                          </div>
                        </div>
            })}

      </div>  
  )
}

export default KimbleBuildGalleryImage
