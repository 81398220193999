import React from "react"
import { Link } from 'gatsby'
import KimbleBuildGalleryImage from "../../../components/KimbleBuildGalleryImage"
import Helmet from '../../../components/Helmet';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import OliveGreenCube from "../../../images/svg/OLIVE_GREEN_cube.inline.svg"

import "../../../components/ContentBlogStyles.scss"


const KimbleBuildGallery = () => {
  return (
    <>
      <Helmet title="Gallery" />
      <Header subNav={true} />

      <main id="gallery">
        <div className="container">
          <section className="section container">
            <div className="content" id="main-content">
              <section className="blog">
                <div className="blog-header">
                  <div className="top-title">
                    <div>
                      <OliveGreenCube aria-hidden="true" />
                      <h1 className="h1">
                        <span>Gallery</span>
                      </h1>
                    </div>
                    <p>One of the main features you can see here is the conversion of the chassis into a unibody fuel tank. The chassis began as a box design and very rigid. I opened it up, restructured the inside, reinforced, baffled, and lined the interior with a polyurea formula (flexible epoxy). Closed it back in with automotive wheel lugs clamping the lids, and added dual sided 2" fuel filler necks for fast fill at a truck stop.</p>
                    <p>Near the end are a number of early photos, including what the truck in its former life as a PG&amp;E water truck looked like. </p>
                    
                  </div>
                  <div className="global-tags tags-header">
                    <h2 className="h1">
                      <span>Overland Rig Build Pics</span>
                    </h2>
                    <h3 className="h3"><Link to="/blog/gallery/kimble-visualization-gallery">View the visualization gallery</Link></h3>
                   
                    <div className="work-tags blog-tags"></div>
                  </div>
                </div>
              </section>

              <section className="blog-summary">
                <KimbleBuildGalleryImage />
              </section>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default KimbleBuildGallery
